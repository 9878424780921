<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.keyword" placeholder="请输入用户昵称/姓名/手机号/专属号/支付宝UID" style="width: 380px;" class="filter-item" clearable/>
      <el-select class="filter-item" v-model="listQuery.member_level" placeholder="积分等级" style="width: 160px" clearable>
        <el-option v-for="item in memberLevels" :key="item.level" :label="item.name" :value="item.level">
        </el-option>
      </el-select>
      <el-select class="filter-item" v-model="listQuery.member_purchase_level" placeholder="已购等级" style="width: 160px" clearable>
        <el-option v-for="item in memberPurchaseLevels" :key="item.level" :label="item.name" :value="item.level">
        </el-option>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
    </div>
    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="支付宝UID" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.alipay_user_id }}
        </template>
      </el-table-column>
      <el-table-column label="姓名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="手机号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.phone }}
        </template>
      </el-table-column>
      <el-table-column label="专属号" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag>{{ scope.row.u_no }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="用户" min-width="110" align="center">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction:column;align-items:center;text-align:center;">
            <img :src="scope.row.avatar" class="user-avatar">
            <span>{{ scope.row.nickname }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="性别" min-width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.gender == 0">未设置</span>
          <span v-else-if="scope.row.gender == 1">男</span>
          <span v-else-if="scope.row.gender == 2">女</span>
        </template>
      </el-table-column>
      <el-table-column label="余额" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.balance }}
        </template>
      </el-table-column>
      <el-table-column label="积分" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.integral }}
        </template>
      </el-table-column>
      <el-table-column label="积分等级" min-width="100" align="center">
        <template slot-scope="scope">
          Lv.{{ scope.row.member_level }} {{scope.row.level.name}}
        </template>
      </el-table-column>
      <el-table-column label="已购等级" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.purchase_level ? scope.row.purchase_level.name : '无' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="注册时间" min-width="200">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ scope.row.created_at }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" fixed="right" align="center" width="230" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button type="primary" size="mini">业务员</el-button>
          <el-button type="primary" size="mini">商家</el-button>
        </template>
      </el-table-column> -->
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      memberLevels: [],
      memberPurchaseLevels: [],
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: "",
        keyword: "",
        member_level: "",
        member_purchase_level: "",
      }
    };
  },
  created() {
    this.getList();
    this.getMemberLevels();
    this.getMemberPurchaseLevels();
  },
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
    },
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/user/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getMemberLevels() {
      this.listLoading = true;
      request({
        url: "/api/backend/memberLevel/list",
        method: "get",
        params: {
          page: 1,
          limit: 100000,
          type: 1
        },
      }).then(response => {
        this.memberLevels = response.data.data;
      });
    },
    getMemberPurchaseLevels() {
      this.listLoading = true;
      request({
        url: "/api/backend/memberLevel/list",
        method: "get",
        params: {
          page: 1,
          limit: 100000,
          type: 2
        },
      }).then(response => {
        this.memberPurchaseLevels = response.data.data;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleForbid(val, text) {
      this.$confirm("此操作将" + text + "该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          request({
            url: "/api/backend/user/forbid",
            method: "get",
            params: { id: val }
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功!"
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
